import '../sass/style.scss'
import { ConfiguratorState } from './configurator-state/configurator-state'
import { RadioInputComponent } from './configurator-state/component/input/radio.input.component'
import pdfMake from "pdfmake/build/pdfmake";

// global vars
const ajaxUrl = '../wp-admin/admin-ajax.php';

// remove hash from url on page reload
history.replaceState(null, null, ' ');
window.history.replaceState({}, document.title, window.location.href.replace(/\??clear-cart&?/, ''));

function initializeConfiguratorState() {
    const configuratorState = new ConfiguratorState()

    configuratorState.init()

    configuratorState.event.addEventListener('valueChange', (event) => {
        const { component } = event.detail

        if (component instanceof RadioInputComponent) {
            $(this).attr('data-status', 'true')
        }

        main_ajax()
    })
}

if (document.querySelector('.configurator__layers')) {
    initializeConfiguratorState()
}

// Take all next elements after class
const nextAll = element => {
    const nextElements = []
    let nextElement = element

    while(nextElement.nextElementSibling) {
        nextElements.push(nextElement.nextElementSibling)
        nextElement = nextElement.nextElementSibling
    }

    return nextElements
}

const prevAll = element => {
    const prevElements = []
    let prevElement = element

    while(prevElement.previousElementSibling) {
        prevElements.push(prevElement.previousElementSibling)
        prevElement = prevElement.previousElementSibling
    }

    return prevElements
}

const isAddons = document.querySelector('.addons');

if (!isAddons) {
    const cartButton = document.querySelector('.to-next.add-to-cart-button');
    if (cartButton) {
        cartButton.classList.add('summ');
        cartButton.setAttribute('data-checkout', '');
        cartButton.innerHTML = 'Abschließen';
    }
}


// final step:
// - show checkout
// - some animations
setTimeout(() => {
    if (document.querySelector('[data-checkout]')) {
        document.querySelector('[data-checkout]').addEventListener('click', () => {
            document.querySelector('.form-loader').style.display = 'flex';

            setTimeout( () => {
                document.querySelectorAll('.configurator__content').forEach(e => {

                    e.style.opacity = '0';

                    setTimeout(()=> {
                        e.style.display = 'none';
                    }, 500)

                    document.querySelector('.right').style.opacity = '0';
                    setTimeout(() => {
                        document.querySelector('.right').style.display = 'none';
                    }, 500);
                    document.querySelector('.configurator .left').style.width = '35%';

                })

                setTimeout(() => {
                    load_checkout();
                }, 1500)
            }, 1000)
        })
    }
}, 100);

// function initOrderEditor() {
//     document.querySelector('.edit-order').addEventListener('click', function() {
//         document.querySelector('.configurator__layer.checkout-layer').style.display='none';
//
//         const centerContent = document.querySelector('.center-out');
//         centerContent.style.width = '0%';
//         centerContent.style.height = '0';
//         centerContent.style.opacity = '0';
//         centerContent.innerHTML = '';
//
//         document.querySelectorAll('.configurator__content[data-title="Zubehör"]').forEach(e => {
//             const addonLayer = document.querySelector('.addons-layer');
//             const addonImg = addonLayer.querySelector('img');
//
//             addonLayer.classList.remove('closed');
//             addonLayer.classList.add('active', 'current');
//             addonImg.setAttribute('src', addonImg.getAttribute('data-active'));
//
//             e.style.display = 'block';
//
//             setTimeout(()=> {
//                 e.style.opacity = '1';
//             }, 500)
//
//             document.querySelector('.right').style.display = 'flex';
//             setTimeout(() => {
//                 document.querySelector('.right').style.opacity = '1';
//             }, 500);
//             document.querySelector('.configurator .left').style.width = '40%';
//
//         })
//     })
// }

// open/close/reset tab in configurator sections
let sectionsWraps = document.querySelectorAll('.configurator__content-sections');
function sectionSwitcher(){
    sectionsWraps.forEach(sectionWrap => {
        let sectionTrigger = sectionWrap.querySelectorAll('.configurator-section');

        sectionTrigger.forEach((item, index) => {
            // indexes
            const sectionIndex = index + 1,
                nextSectionIndex = sectionIndex + 1,
                prevSectionIndex = sectionIndex - 1,

                // vars
                sectionTab = item.querySelector('.configurator-section__row'),
                sectionContent = item.querySelector('.configurator-section__content'),
                sectionReset = item.querySelector('.section-reset'),
                inputRadio = item.querySelectorAll('input[type=radio]'),

                // detect section position
                nextSection = sectionWrap.querySelector('.configurator-section.step-' + nextSectionIndex),
                currentSection = sectionWrap.querySelector('.configurator-section.step-' + sectionIndex),
                prevSection = sectionWrap.querySelector('.configurator-section.step-' + prevSectionIndex);


            inputRadio.forEach(el => {
                el.addEventListener('click', () => {
                    // TODO: tests
                    const sectionDelay = 1000;

                    function scroll() {
                        const currentContent = document.querySelector('.configurator__content.active.current'),
                            currentContentheight = currentContent.offsetHeight;

                        currentContent.scrollBy({top: currentContentheight, left: 0, behavior: "smooth"});
                    }

                    item.setAttribute('data-section-status', 'checked');

                    sectionTab.classList.add('active');
                    sectionTab.classList.remove('deactivate-row');

                    // next step open
                    if (nextSection && !nextSection.classList.contains('hide-section')) {
                        setTimeout(() => nextSection.classList.add('active', 'current'), sectionDelay);
                    }

                    if (nextSection && nextSection.classList.contains('hide-section')) {
                        const nextAfterBlocked = nextSection.nextElementSibling;
                        nextAfterBlocked.classList.add('active', 'current');
                    }



                    // Scroll to next values after click
                    // setTimeout(scroll, sectionDelay);
                    checkIfInputChanged();

                    let test = el.getAttribute('name');

                    item.querySelectorAll('input[name="' + test + '"]').forEach(el => {
                        el.setAttribute('data-waschecked', true);
                    })

                    function checkIfInputChanged() {

                        if (el.getAttribute('data-waschecked') == 'true') {

                            let allNextSections = sectionWrap.querySelectorAll('.configurator-section.active');

                            allNextSections.forEach(activeSection => {

                                document.querySelectorAll('.to-next_wrap').forEach(resetNextButton => {
                                    if (!resetNextButton.classList.contains('addons') || !resetNextButton.classList.contains('addons') && resetNextButton.classList.contains('summ')) {
                                        resetNextButton.classList.remove('active');
                                    }
                                })

                                // TODO: rebuild before go live
                                if (!activeSection.classList.contains('step-' + sectionIndex) && !activeSection.classList.contains('step-' + prevSectionIndex) && !activeSection.classList.contains('step-' + (prevSectionIndex-1)) && !activeSection.classList.contains('step-' + (prevSectionIndex-2)) && !activeSection.classList.contains('step-' + (prevSectionIndex-3)) && !activeSection.classList.contains('step-' + (prevSectionIndex-4)) && !activeSection.classList.contains('step-' + (prevSectionIndex-5)) && !activeSection.classList.contains('step-' + (prevSectionIndex-6)) && !activeSection.classList.contains('step-' + (prevSectionIndex-7)) && !activeSection.classList.contains('step-' + (prevSectionIndex-8)) && nextSection) {

                                    // TODO: reset section doesnt work correctly
                                    activeSection.classList.remove('active');
                                    nextSection.classList.add('active');

                                    activeSection.setAttribute('data-section-status', 'unchecked');

                                    activeSection.querySelectorAll('input[type=radio]').forEach(el => {
                                        el.checked = false;
                                    })

                                    activeSection.querySelectorAll('input').forEach(el => {
                                        el.setAttribute('data-waschecked', false);
                                    })
                                }
                            })
                        }
                    }

                    var values = $('input[type="radio"]:checked').map(function() {
                        return $(this).val();
                    }).get();

                    if (document.querySelector('.configurator__layers [data-title="Schliesssystem"]')) {
                        const sclSystem = 'nein';
                        if (values.includes(sclSystem)) {
                            document.querySelector('.configurator__layers [data-title="Schliesssystem"]').style.display = 'none';
                        } else {
                            document.querySelector('.configurator__layers [data-title="Schliesssystem"]').style.display = 'flex';
                        }
                    }

                    const silber = 'silbergrau';
                    if (values.includes(silber)) {
                        document.querySelectorAll('.configurator__content:not([data-title="Führungssystem"], [data-title="Masse"], [data-title="Schliesssystem"]) .configurator-section:not([data-slug="farbe"], [data-slug="farbe-karteischrank"]) .if-color-silver').forEach(e => {
                            if (e.innerHTML == '' && e.nextElementSibling.querySelector('.value-image')) {
                                e.innerHTML = '<div class="tip type-2"><span></span><p>Die Farbe der Abbildungen enspricht nicht der vorausgewählten Farbe.</p></div>';
                            }
                        })

                    } else {
                        document.querySelectorAll('.if-color-silver').forEach(e => {
                            e.innerHTML = '';
                        })
                    }


                    const current = document.querySelector('.configurator__content.active.current'),
                        nextSectionOfCurrentLayer = current.querySelectorAll('.configurator-section[data-section-status="checked"]'),
                        getLastChecked = nextSectionOfCurrentLayer[nextSectionOfCurrentLayer.length- 1];

                    const countRadios = getLastChecked.querySelectorAll('.radio-value-box:not(.none)').length;
                    if (countRadios !== 1) {
                        console.log(countRadios);
                        setTimeout(scroll, sectionDelay)
                    }

                    // will work after to show button
                    if (!nextSection) {
                        nextStep();
                    }
                })
            });
        })
    })
}
sectionSwitcher()

const radios = document.querySelectorAll(".dropdown__switch");
let clicks = 0;

radios.forEach(radio => {
    const getLabel = radio.nextElementSibling,
        getDropdown = getLabel.querySelector('.dropdown__select'),
        getDropdownLis = getDropdown.querySelectorAll('li'),
        getLabelParent = getLabel.parentNode,
        getMainParent = getLabelParent.parentNode;


    getLabel.addEventListener('click', ()=> {
        clicks++;
        if (clicks === 2) {
            radio.removeAttribute("disabled");
            clicks = 0;
        }

        if (clicks === 1) {
            radio.setAttribute("disabled", true);
        }

        getMainParent.style.paddingBottom = getDropdown.offsetHeight+'px';
        document.querySelector('.configurator__content.active.current').scrollBy({ top: getDropdown.offsetHeight, left: 0, behavior: "smooth" });
    })

    getDropdownLis.forEach(getDropdownLi => {
        getDropdownLi.addEventListener('click', (event)=> {
            if (getDropdownLi === event.target) {
                getMainParent.classList.add('unset-padding');
            }

            setTimeout(() => {
                if (getMainParent.classList.contains('unset-padding')) {
                    getMainParent.style.paddingBottom = '0';
                }
            }, 100)
        })
    })

    radio.setAttribute("disabled", true);
})

function main_ajax() {
    var values = $('input[type="radio"]:checked').map(function() {
        return $(this).val();
    }).get();

    var staticArr = $('input[type="radio"]').map(function() {
        return $(this).val();
    }).get();

    console.log(values);

    // Make an Ajax request to retrieve the dynamic array
    $.ajax({
        url: '../wp-content/themes/bbp/get_related_product_categories.php',
        type: 'POST',
        dataType: 'json',
        data: {
            category: values,
        },
        beforeSend: () => {
            document.querySelector('.step-loader').classList.add('active');
        },
        success: function(data) {
            var dynamicSet = new Set(Object.values(data))
            var items = staticArr.filter(function (item) {
                return !dynamicSet.has(item)
            });

            // TODO: rebuild
            const containerMitKern = document.querySelector('.radio-value-box[data-slug="schloss-mit-kern"]');
            if (values.includes('haefele-schloss-typ')) {
                containerMitKern.classList.add('none', 'exception');
            } else {
                if (containerMitKern && containerMitKern.classList.contains('exception')) {
                    containerMitKern.classList.remove('none', 'exception');
                }
            }

            const mitKern = document.querySelector('.radio-value-box[data-slug="schloss-mit-kern-karteischrank"]');
            if (values.includes('haefele')) {
                mitKern.classList.add('none', 'exception');
            } else {
                if (mitKern && mitKern.classList.contains('exception')) {
                    mitKern.classList.remove('none', 'exception');
                }
            }

            // TODO: ohne-materialauszug
            if (values.includes('ohne-materialauszug')) {
                const matSection = document.querySelector('.configurator-section.step-4[data-slug="materialauszug"]'),
                    matSectionNext = document.querySelector('.configurator-section.step-5[data-slug="hoehenraster"]');

                if (matSection) {
                    matSection.classList.remove('active', 'current');
                    matSectionNext.classList.add('active', 'current');
                }
            }

            const livaSection4 = document.querySelector('.configurator-section.step-5[data-slug="zylinder"]');

            const livaSection7 = document.querySelector('.configurator-section.step-8[data-slug="farbe-griffleisten"]');
            const livaSection8 = document.querySelector('.configurator-section.step-9[data-slug="distanzset"]');
            const livaSection9 = document.querySelector('.configurator-section.step-10[data-slug="farbe-distanzset"]');
            const livaSection5 = document.querySelector('.configurator-section.step-6[data-slug="farbe-gehaeuse"]');

            if (values.includes('liva-box-stahl') && livaSection4) {
                livaSection4.classList.add('none', 'exception');
                livaSection7.classList.add('none', 'exception');
                livaSection8.classList.add('none', 'exception');
                livaSection9.classList.add('none', 'exception');

                if (document.querySelector('.configurator-section.step-4[data-slug="schloss"][data-section-status="checked"]')) {
                    document.querySelector('.configurator-section.step-6[data-slug="farbe-gehaeuse"]').classList.add('active', 'current');
                }

            } else {
                if (livaSection4 && livaSection4.classList.contains('exception')) {
                    livaSection4.classList.remove('none', 'exception');
                    livaSection7.classList.remove('none', 'exception');
                    livaSection8.classList.remove('none', 'exception');
                    livaSection9.classList.remove('none', 'exception');
                }
            }

            const grosseSection2 = document.querySelector('.configurator-section.step-2[data-slug="groesse"]');
            const grosseSection3 = document.querySelector('.configurator-section.step-3[data-slug="schloss"]');

            if (values.includes('orgawork-kunststoff') && grosseSection2) {
                grosseSection2.classList.add('none', 'exception');
                grosseSection3.classList.add('active', 'current');

            } else {
                if (grosseSection2 && grosseSection2.classList.contains('exception')) {
                    grosseSection3.classList.remove('active', 'current');
                    grosseSection2.classList.remove('none', 'exception');
                }
            }

            const containerKernOhneSchloss = document.querySelector('.configurator-section.step-2[data-slug="schlosskern"]');
            const containerOhneSchloss = document.querySelector('.configurator-section.step-3[data-slug="schlosskern"]');
            if (values.includes('ohne-schloss-schloss-typ') && containerKernOhneSchloss && containerOhneSchloss) {
                containerKernOhneSchloss.classList.add('none', 'exception');
                containerOhneSchloss.classList.add('none', 'exception');
                document.querySelector('.configurator__content[data-title="Schliesssystem"] .to-next_wrap').classList.add('active');
            } else {
                if (containerOhneSchloss && containerKernOhneSchloss && containerKernOhneSchloss.classList.contains('exception')) {
                    containerKernOhneSchloss.classList.remove('none', 'exception');
                    containerOhneSchloss.classList.remove('none', 'exception');
                }
            }

            const kartKernOhneSchloss = document.querySelector('.configurator-section.step-3[data-slug="schlosskern-karteischrank"]');
            const kartOhneSchloss = document.querySelector('.configurator-section.step-4[data-slug="schlosskern-karteischrank"]');
            if (values.includes('ohne-schloss') && kartKernOhneSchloss && kartOhneSchloss) {
                kartKernOhneSchloss.classList.add('none', 'exception');
                kartOhneSchloss.classList.add('none', 'exception');
                document.querySelector('.configurator__content[data-title="Schliesssystem"]').classList.add('next-is-sum');
                document.querySelector('.configurator__content[data-title="Schliesssystem"] .to-next_wrap').classList.add('active');
            } else {
                if (kartOhneSchloss && kartKernOhneSchloss && kartKernOhneSchloss.classList.contains('exception')) {
                    kartKernOhneSchloss.classList.remove('none', 'exception');
                    kartOhneSchloss.classList.remove('none', 'exception');
                    document.querySelector('.configurator__content[data-title="Schliesssystem"] .to-next_wrap').classList.remove('active');
                }
            }

            const kartOhneSchlossQuantity = document.querySelector('.configurator-section.step-2[data-slug="schubstangenschloss"]');
            if (values.includes('nein-schliessstange') && kartOhneSchlossQuantity) {
                kartOhneSchlossQuantity.classList.add('none', 'exception');
                kartKernOhneSchloss.classList.add('none', 'exception');
                kartOhneSchloss.classList.add('none', 'exception');
                document.querySelector('.configurator__content[data-title="Schliesssystem"]').classList.add('next-is-sum');
                document.querySelector('.configurator__content[data-title="Schliesssystem"] .to-next_wrap').classList.add('active');
                document.querySelector('.configurator__content[data-sum] .to-next_wrap').classList.add('active');
            } else {
                if (kartOhneSchlossQuantity && kartOhneSchlossQuantity.classList.contains('exception')) {
                    kartOhneSchlossQuantity.classList.remove('none', 'exception');
                    kartKernOhneSchloss.classList.remove('none', 'exception');
                    kartOhneSchloss.classList.remove('none', 'exception');
                    document.querySelector('.configurator__content[data-sum] .to-next_wrap').classList.remove('active');
                }
            }

            // TODO: do this with php

            const keyTab = document.querySelector('a[data-title="Schliesssystem"]');
            if (keyTab) {
                const keyPop = keyTab.previousElementSibling,
                    keySection = document.querySelector('.configurator__content[data-title="Schliesssystem"]'),
                    keySectionNextButton = document.querySelector('.configurator__content[data-title="Schliesssystem"] .to-next'),
                    keySectionValues = document.querySelector('.configurator-section.step-4[data-slug="schliesssystem-container"]'),
                    keySectionPrev = document.querySelector('.configurator__content[data-title="Führungssystem"]'),
                    activateButtons = document.querySelector('.configurator__content[data-title="Ausstattung"] .to-next_wrap'),
                    sumLayer = document.querySelector('.sum-layer'),
                    keySectionPrevButton = document.querySelector('.configurator__content[data-title="Führungssystem"] .to-next');

                if (values.includes('nein-container') && keyTab && keySection) {
                    document.querySelector('input[value="wip-fbf-5-container"]').click();

                    keySectionValues.classList.add('none', 'exception');
                    keySectionPrev.classList.add('next-is-sum');
                    keySection.classList.remove('next-is-sum');
                    keyTab.classList.add('none', 'exception');
                    keyPop.classList.add('none', 'exception');
                    keySection.classList.add('none', 'exception');

                    activateButtons.classList.add('active');
                    keySection.classList.remove('active', 'current');
                    keySection.style.display = 'none';

                    keySectionPrevButton.addEventListener('click', function () {
                        keySection.classList.remove('active', 'current');
                        keySectionPrev.classList.remove('active', 'current');
                        //keySectionPrev.classList.add('finished');
                        //keySection.classList.add('finished');

                        sumLayer.classList.add('active', 'current');
                        document.querySelector('[data-sum]').classList.add('active', 'current');

                        console.log('FIRE')

                        setTimeout(() => {
                            document.querySelector('.configurator__content[data-title="Zubehör"]').classList.remove('active');

                            //  loadSummAjax();
                        }, 100)
                    })

                } else if (!values.includes('nein-container') && keyTab && keySection.classList.contains('exception')) {
                    keySectionValues.classList.remove('none', 'exception');
                    keySectionPrev.classList.remove('next-is-sum');
                    keySection.classList.add('next-is-sum');
                    keyTab.classList.remove('none', 'exception');
                    keyPop.classList.remove('none', 'exception');
                    keySection.classList.remove('none', 'exception');

                    keySectionPrev.removeAttribute('style');
                    keySection.removeAttribute('style');

                    keySectionPrevButton.addEventListener('click', function () {
                        sumLayer.classList.remove('active', 'current');
                        document.querySelector('[data-sum]').classList.remove('active', 'current');
                    })

                    keySectionNextButton.addEventListener('click', function () {
                        //loadSummAjax();
                    })
                }
                loadSummAjax();
            }

            const schlossSection = document.querySelector('.configurator-section.step-3[data-slug="schlosskern"]')
            const activateButtons = document.querySelector('.configurator__content[data-title="Schliesssystem"] .to-next_wrap')

            if (values.includes('schloss-ohne-kern') && schlossSection) {
                schlossSection.classList.add('none', 'exception')
                activateButtons.classList.add('active')

                // nextStep();
            } else {
                if (schlossSection && schlossSection.classList.contains('exception')) {
                    schlossSection.classList.remove('none', 'exception')
                    activateButtons.classList.remove('active')
                }
            }

            if (values.includes('schloss-ohne-kern-karteischrank')) {
                const schlossSection = document.querySelector('.configurator-section.step-4[data-slug="schlosskern-karteischrank"]'),
                    activateButtons = document.querySelector('.configurator__content[data-title="Schliesssystem"] .to-next_wrap');

                if (schlossSection) {
                    schlossSection.remove();
                    activateButtons.classList.add('active');
                }

                nextStep();
            }

            if (values.includes('zahlen-schloss')) {
                const schlossSection = document.querySelector('.configurator-section[data-slug="zylinder"]'),
                    schlossSectionNext = document.querySelector('.configurator-section[data-slug="farbe-gehaeuse"]');

                if (schlossSection) {
                    schlossSection.remove();
                    schlossSectionNext.classList.add('active', 'current');
                }
            }

            if (
                values.includes('verkehrsweiss') ||
                values.includes('schwarz-farbe-gehaeuse') ||
                values.includes('reinweiss') ||
                values.includes('anthrazit') ||
                values.includes('verkehrsweiss') ||
                values.includes('weiss') ||
                values.includes('weissaluminium') ||
                values.includes('schwarz-livabox')
            ) {
                const colorSection1 = document.querySelector('.configurator-section[data-slug="farbe-frontblende"]'),
                    colorSection2 = document.querySelector('.configurator-section[data-slug="farbe-griffleisten"]'),
                    distanzet = document.querySelector('.configurator-section[data-slug="distanzset"]'),
                    activateButtons = document.querySelector('.configurator__content[data-title="UNTERBAUSCHUBLADE"] .to-next_wrap');


                if (colorSection1 || colorSection2) {
                    const label1 = document.querySelectorAll('.configurator-section[data-slug="farbe-frontblende"] input + label'),
                        label2 = document.querySelector('.configurator-section[data-slug="farbe-griffleisten"] input + label'),
                        label3 = document.querySelectorAll('.configurator-section[data-slug="farbe-frontblende"] input + label + div > label'),
                        label4 = document.querySelector('.configurator-section[data-slug="farbe-griffleisten"] input + label + div > label');


                    if (values.includes('liva-box-stahl')) {
                        colorSection1.classList.add('active', 'current');
                        activateButtons.classList.add('active');

                        nextStep();

                    } else {
                        colorSection1.classList.add('active', 'current');
                        colorSection2.classList.add('active', 'current');
                        distanzet.classList.add('active', 'current');
                    }

                    label1.forEach(e => {
                        e.style.display = 'none';
                    })

                    label3.forEach(e => {
                        e.style.pointerEvents = 'none';
                    })

                    label2.style.display = 'none';
                    label4.style.pointerEvents = 'none';
                }
            }

            if (values.includes('ja-distanzset')) {
                const colorDistanzsetLabel = document.querySelectorAll('.configurator-section[data-slug="farbe-distanzset"] input + label'),
                    label3 = document.querySelectorAll('.configurator-section[data-slug="farbe-distanzset"] input + label + div > label');

                colorDistanzsetLabel.forEach(e => {
                    e.style.display = 'none';
                })

                label3.forEach(e => {
                    e.style.pointerEvents = 'none';
                })
                nextStep();
            }

            if (values.includes('nein-distanzset')) {
                const colorDistanzsetSection = document.querySelector('.configurator-section[data-slug="farbe-distanzset"]');

                if (colorDistanzsetSection) {
                    colorDistanzsetSection.remove();
                }

                nextStep();
            }

            items.forEach(i => {
                const current = document.querySelector('.configurator__content.active.current'),
                    next = current.nextElementSibling,
                    getValue = document.querySelector('.configurator__content.active.current .configurator-section:not([data-section-status="checked"]) .radio-value-box[data-slug="'+i+'"]'),
                    setForNext = next.querySelector('.configurator-section.step-1 .radio-value-box[data-slug="'+i+'"]');

                if (getValue) {
                    getValue.classList.add('none');
                }

                if (setForNext) {
                    setForNext.classList.add('none');
                }
            })

            if (values.includes('container') || values.includes('karteischrank')) {
                const current = document.querySelector('.configurator__content.active.current'),
                    nextSectionOfCurrentLayer = current.querySelectorAll('.configurator-section[data-section-status="checked"]'),
                    getLastChecked = nextSectionOfCurrentLayer[nextSectionOfCurrentLayer.length- 1],
                    nextStep = getLastChecked && getLastChecked.nextElementSibling !== 'undefined' ? getLastChecked.nextElementSibling : current.querySelector('.configurator-section.step-1');

                if (nextStep) {
                    const countRadios = nextStep.querySelectorAll('.radio-value-box:not(.none)').length;

                    if (countRadios === 1) {
                        const getOneValue = nextStep.querySelector('.radio-value-box:not(.none)'),
                            getOneInput = getOneValue.querySelector('input');

                        if (values.includes('container') && nextStep.getAttribute('data-slug') === 'schubladen') {
                            nextStep.querySelector('[data-slug="schubladen"] .configurator-section__content > .tip').style.display = 'none';
                        }

                        getOneInput.click();
                    }

                    if (values.includes('teilauszug-tipair') && nextStep.getAttribute('data-slug') === 'fuehrung') {
                        nextStep.querySelector('[data-slug="fuehrung"] .configurator-section__content > .tip').style.display = 'none';
                    }
                }
            }

            const allRadios = document.querySelectorAll('.radio-value-box');

            allRadios.forEach(radio => {
                const dataSlug = radio.getAttribute('data-slug');

                if (!items.includes(dataSlug) && !radio.classList.contains('exception')) {
                    radio.classList.remove('none');
                }
            })
        },
        complete: function() {
            document.querySelector('.step-loader').classList.remove('active');
            scroll();
        }
    });

}

const resultArray  = [];

// Get list of selected items for 'CONTAINER MENGE' step

// function filterSelected() {
if (document.querySelector('.configurator__content[data-sum]')) {
    const stepBeforeSummary = document.querySelector('.configurator__content[data-sum]').previousElementSibling;
    let stepBeforeSummaryBtn = stepBeforeSummary.querySelector('.to-next');

    $(stepBeforeSummaryBtn).on('click', () => {
        console.log('button clicked')
        loadSummAjax();
    });
}

// }

// filterSelected();

function loadSummAjax() {
    console.log('made ajax')

    const summary = document.querySelector('.summary');

    var values = $('input[type="radio"]:checked').map(function() {
        return $(this).val();
    }).get();

    $.ajax({
        type: 'POST',
        url: ajaxUrl,
        dataType: 'html',
        data: {
            action: 'filter_selected',
            selected: values,
        },
        beforeSend: () => {
            document.querySelector('.summary').appendChild(document.getElementById('loader').content.cloneNode(true));
        },
        success: (selected_res) => {
            summary.innerHTML = selected_res;
            summary.style.display = 'none';
        },
        complete: () => {
            setTimeout(() => {summary.style.display = 'flex';}, 100)
        }
    });

    $.ajax({
        type: 'POST',
        url: ajaxUrl,
        dataType: 'html',
        data: {
            action: 'filter_products',
            category: values,
        },
        success: function (res) {
            // console.log(res)
            var response = JSON.parse(res);
            var sku = response['sku'];
            var product_id = response['id'];

            var $cart_button = $('.add-to-cart-button.to-next');

            $cart_button.attr('data-product', product_id);
            $cart_button.attr('data-quantity', '1');

            document.querySelector('.quantity').setAttribute('data-product', product_id);

            setTimeout(() => {
                document.querySelector('.total-product').innerHTML = '' +
                    // '<button class="add-to-cart-button btn-cart-style" data-product="'+product_id+'" data-quantity="1">Konfiguration speichern <span></span></button>' +
                    '<div class="main-product">' +
                    '<strong>Produktnummer: </strong>' +
                    '<div class="main-product-sku" style="margin-left: .5rem">'+sku+'</div>' +
                    '</div>';
            }, 1500)
        },
        complete: function () {
            const anzahl = document.querySelector('.anzahl');

            setTimeout(() => {
                anzahl.classList.add('active');
            }, 1500)
        }
    })
}

// Add product to cart
function add_to_cart_by_id( id, quantity ) {
    const sum = document.querySelector('.summary').innerHTML;

    $.ajax({
        type: 'POST',
        url: ajaxUrl,
        data: {
            action: 'add_to_cart_item',
            product_id: id,
            quantity: quantity,
            div_content: sum,
        },
        success: function( response ) {
            var cart = JSON.parse(response);
            var quantity = cart['quantity'];
            if (quantity > 1) {
                var buttonText = '<div>'+quantity+'</div>' + ' Konfigurationen';
            } else {
                var buttonText = '<div>'+quantity+'</div>' + ' Konfiguration';
            }

            const isAddons = document.querySelector('.addons');

            if (isAddons) {
                $('.to-next.add-to-cart-button').removeClass('add-to-cart-button');
                $('.add-to-cart-button.btn-cart-style').remove();
            }

            $('.anzahl').remove();
            $('.configurator__layers a.finished').removeClass('finished').addClass('closed');

            document.querySelectorAll('.site-header-cart .btn-1').forEach(cartBtn => {
                cartBtn.innerHTML = '' +
                    '<svg xmlns="http://www.w3.org/2000/svg" width="19.055" height="23.818" viewBox="0 0 19.055 23.818">' +
                    '   <path id="cart" d="M10.764,19.673h9.527a1.191,1.191,0,0,1,0,2.382H9.573a1.191,1.191,0,0,1-1.191-1.191V5.382H7.191A1.191,1.191,0,0,1,7.191,3H9.573a1.191,1.191,0,0,1,1.191,1.191V6.574l.087,0H22.586a2.426,2.426,0,0,1,2.468,2.382,2.32,2.32,0,0,1-.06.517l-1.371,5.955a2.45,2.45,0,0,1-2.41,1.865H10.851l-.087,0Zm-.6,7.146a1.786,1.786,0,1,1,1.786-1.786A1.786,1.786,0,0,1,10.168,26.818Zm10.718,0a1.786,1.786,0,1,1,1.786-1.786A1.786,1.786,0,0,1,20.886,26.818ZM10.764,8.955v5.955H21.28l1.393-5.955Z" transform="translate(-6 -3)" fill="#727981" fill-rule="evenodd"/>' +
                    '</svg>' +
                    '<span class="amount">'+buttonText+'</span>';
            })
        }
    });
}
// Handle click event on "Add to cart" button
$( document ).on( 'click', '.add-to-cart-button', function( event ) {
    event.preventDefault();
    var id = $( this ).data( 'product' );
    var quantity = $( this ).data( 'quantity');

    add_to_cart_by_id( id, quantity );

    var values = $('input[type="radio"]:checked').map(function() {
        return $(this).val();
    }).get();

    const isAddons = document.querySelector('.addons');

    if (isAddons) {
        $.ajax({
            type: 'POST',
            url: ajaxUrl,
            data: {
                action: 'load_addons',
                category: values,
                parent: id
            },
            beforeSend: () => {
                const addons = document.querySelector(".type3.addons");
                addons.appendChild(document.getElementById('loader').content.cloneNode(true));
            },
            success: function (response) {
                const addons = document.querySelector(".type3.addons"),
                    addonsSvg = addons.querySelector("svg");

                addonsSvg.remove();

                addons.innerHTML = response;

                if (window.innerWidth < 767) {
                    document.querySelectorAll('.remove-on-mob').forEach(i => {i.remove()})
                }

                const notificationIfSilverForSpecialAddon = document.querySelector('.show-if-color-silver');
                if (values.includes('schwarz-farbe-karteischrank') && notificationIfSilverForSpecialAddon) {
                    notificationIfSilverForSpecialAddon.style.display = 'none';
                }

                const jsKorbAddon = document.querySelector('.js-korb-addon');
                if (values.includes('600') && (values.includes('392-mm') || values.includes('416-mm-speed-office')) && jsKorbAddon) {
                    jsKorbAddon.querySelector('.parent-info').innerHTML = 'Bei Tiefe 600 sind pro Schubkasten 1 Stück Hängekorb A4 und 2 Stück Adapter erforderlich.';
                }

                if (values.includes('800') && jsKorbAddon) {
                    jsKorbAddon.querySelector('.parent-info').innerHTML = 'Bei Tiefe 800 sind pro Schubkasten 2 Stück Hängekörbe A4 erforderlich.';
                }

                const forProductSize = document.querySelectorAll('.special-files');

                if (forProductSize) {
                    forProductSize.forEach(i => {
                        if (
                            values.includes('361') ||
                            values.includes('375') ||
                            values.includes('390') ||
                            values.includes('400')
                        ) {
                            i.querySelectorAll('[data-product-size="600"]').forEach(e => {e.style.display='none'});
                        } else {
                            i.querySelectorAll('[data-product-size="400"]').forEach(e => {e.style.display='none'});
                        }

                        if (values.includes('320')) {
                            const specialAddonNotification = document.querySelector('.js-lochblech-addon-special-section .parent-info');

                            if (specialAddonNotification) {
                                specialAddonNotification.style.display = 'none'
                            }

                            i.querySelectorAll('[data-product-size="600"]').forEach(e => {e.style.display='none'});
                            i.querySelectorAll('[data-product-size="400"]').forEach(e => {e.style.display='none'});
                        }
                    })
                }
            },
            complete: () => {
                const notification = document.querySelector('.notification');

                notification.classList.add('active');

                document.querySelectorAll('.add-to-cart-addon').forEach(item => {
                    item.setAttribute('data-parent', id);
                })
                setQuantity();

                addAddonBtnEvent();
            }
        });
    }
});

function load_checkout() {
    const centerContent = document.querySelector(".center-out");

    $.ajax({
        type: 'POST',
        url: ajaxUrl,
        data: {
            action: 'load_checkout',
        },
        success: function( response ) {
            centerContent.style.width = '100%';
            centerContent.style.height = 'auto';
            centerContent.style.opacity = '1';
            centerContent.innerHTML = response;

            // initOrderEditor();

            setTimeout(() => {
                document.querySelector('.form-loader').style.display = 'none';
            }, 1500)
        },
    });
}

function addAddonBtnEvent() {
    const addToCartAddonBtns = document.querySelectorAll('.add-to-cart-addon');
    addToCartAddonBtns.forEach(addToCartAddonBtn => {
        addToCartAddonBtn.addEventListener('click', function () {

            this.dataset.buffer = this.dataset.quantity;

            addToCartAddon(this);
        })
    })

}
function addToCartAddon($this) {
    const id = $this.dataset.product,
        quantity = $this.dataset.quantity,
        parent = $this.dataset.parent;

    console.log(quantity)

    add_to_cart_by_id( id, quantity );

    addToCartAddonAjax(id,parent);
}

function addToCartAddonAjax(id,parent) {
    $.ajax({
        type: 'POST',
        url: ajaxUrl,
        data: {
            action: 'add_to_grouped',
            addon: id,
            parent: parent,
        },
        beforeSend: function () {
            const
                currentAddonBox = document.querySelector('.addons_item[data-product="'+id+'"]'),
                currentAddonButton = currentAddonBox.querySelector('.add-to-cart-addon');

            currentAddonButton.style.pointerEvents = 'none';
            currentAddonButton.innerHTML = '';
            currentAddonButton.appendChild(document.getElementById('loader').content.cloneNode(true));
        },
        success: function( response ) {
            // console.log(quantity)

            const currentAddon = document.querySelector('.addons_item[data-product="'+id+'"] .configurator-values_value'),
                currentAddonBox = document.querySelector('.addons_item[data-product="'+id+'"]'),
                currentAddonButton = currentAddonBox.querySelector('.add-to-cart-addon');

            currentAddonButton.classList.add('active');
            currentAddonButton.innerHTML = 'Hinzugefügt<span></span>';

            if (currentAddon.classList.contains('addon-parent')) {
                document.querySelectorAll('.addon-child').forEach(e => {
                    e.classList.remove('addon-child');
                })

                document.querySelector('.special-section[data-product="'+id+'"]').style.display = 'flex';
            }

            if (currentAddon.classList.contains('addon-parent-korb')) {
                document.querySelectorAll('.addon-child-korb').forEach(e => {
                    e.classList.remove('addon-child-korb');
                })

                document.querySelector('.special-section[data-product="'+id+'"]').style.display = 'flex';
            }
        }
    });
}

$(document).ready(function() {
    $('.js-tab-trigger').click(function() {
        $('.js-tab-trigger.active').removeClass('active');
        $(this).addClass('active');
    });
});

const configuratorSectionContent = document.querySelectorAll('.configurator-section__content');
configuratorSectionContent.forEach(e => {
    const tipLink = e.querySelector('.tip a');

    if(tipLink) {
        tipLink.addEventListener('click', () => {
            const modal = e.querySelector('.tip + .overlay');
            const modalId = modal.getAttribute('id');
            const getForm = tipLink.getAttribute('href');

            if (getForm === '#form') {
                tipLink.setAttribute('data-form-type', getForm);
            }

            if (tipLink.dataset.formType) {
                tipLink.setAttribute('href', '#form');
                modal.setAttribute('id', 'form');
                modal.querySelector('h2').innerHTML = 'Anfrage senden';
                modal.querySelector('.content').innerHTML = '';
                modal.querySelector('.content').appendChild(document.getElementById('form-form').content.cloneNode(true));
            } else {
                tipLink.setAttribute('href', '#'+modalId);
            }
        });
    }
})

// To next step
function nextStep() {
    const sectionContents = document.querySelectorAll('.configurator__content');

    sectionContents.forEach(sectionContent => {
        sectionContent.querySelector('.to-next_wrap').classList.add('active');

        const toNextStep = sectionContent.querySelector('.to-next');

        toNextStep.addEventListener('click', () => {

            sectionContent.classList.add('finished');

            if (sectionContent.classList.contains('finished')) {

            }

            if (sectionContent.classList.contains('target')) {
                sectionContent.classList.remove('target');
            }

            sectionContent.classList.remove('active');

            setTimeout(switchActive, 10);
            setTimeout(removeCurrent, 50);
            setTimeout(addCurrent, 90);
            setTimeout(resetNextBtn, 1);
            notEmpty();
            function notEmpty() {
                const parentElement = document.querySelector('.configurator-values');
                const childElements = parentElement.querySelectorAll('.radio-value-box');
                const allElementsHaveJSAttribute = [...childElements].every(element => {
                        if(element.getAttribute('style') === 'display: none;') {
                            setTimeout(switchActive, 10);
                            setTimeout(removeCurrent, 50);
                            setTimeout(addCurrent, 90);
                            setTimeout(resetNextBtn, 1);
                        }
                    }
                );
            }

            function switchActive(){
                const nextContent = document.querySelector('.configurator__content.current + .configurator__content');
                nextContent.classList.add('active');

                if (nextContent.getAttribute('data-title') === 'Führungssystem' && document.querySelector('[name="main-product-type"]').getAttribute('value') === 'karteischrank') {
                    document.querySelector('[data-slug="fuehrung-karteischrank"] input').click();
                    document.querySelector('[data-title="Führungssystem"] .to-next_wrap').classList.add('active');
                }

                const checkFirstStep = nextContent.querySelector('.step-1');
                if (checkFirstStep) {
                    const countRadios = checkFirstStep.querySelectorAll('.radio-value-box:not(.none)').length;

                    if (countRadios === 1) {
                        const getOneValue = checkFirstStep.querySelector('.radio-value-box:not(.none)'),
                            getOneInput = getOneValue.querySelector('input');

                        getOneInput.click();
                    }
                }

                const layerCurrent = document.querySelector('.configurator__layers a.current'),
                    layerNext = document.querySelector('.configurator__layers a.current + .overlay + a'),
                    layerCurrentIcon = layerCurrent.querySelector('.layer-icon'),
                    layerNextIcon = layerNext.querySelector('.layer-icon'),
                    layerCurrentIconFinished = layerCurrentIcon.getAttribute('data-finished'),
                    layerNextIconActive = layerNextIcon.getAttribute('data-active');

                layerCurrent.classList.remove('active');
                layerCurrentIcon.setAttribute('src', layerCurrentIconFinished);

                layerNext.classList.add('active');
                layerNextIcon.setAttribute('src', layerNextIconActive);
            }

            function removeCurrent(){
                const current = document.querySelector('.configurator__content.current');

                sectionContent.classList.remove('current');

                const currentLayer = document.querySelector('.configurator__layers a.current');

                if (!currentLayer.classList.contains('sum-layer') && !currentLayer.classList.contains('addons-layer')) {
                    currentLayer.classList.add('finished');
                } else {
                    currentLayer.classList.add('closed');
                }

                setTimeout(()=>{currentLayer.classList.remove('current');}, 100);
            }

            function addCurrent() {
                const active = document.querySelector('.configurator__content.active');

                active.classList.add('current');

                document.querySelector('.configurator__layers a.active:not(.finished)').classList.add('current');
            }

            // wip
            function resetNextBtn () {
                document.querySelectorAll('.to-next_wrap').forEach(nextBtn => {
                    const check = nextBtn.querySelector('.to-next').classList.contains('add-to-cart-button');

                    if (!check) {
                        nextBtn.classList.remove('active');
                    }
                })
            }
        })
    })
}

// reset one step back on click
const toBack = document.querySelectorAll('.to-back');
toBack.forEach(back => {
    back.addEventListener('click', () => {
        if (!back.classList.contains('reset-all')) {
            const backData = back.getAttribute('data-title'),
                layerTab = document.querySelector('.configurator__layers a[data-title="'+backData+'"]'),
                getModal = layerTab.previousElementSibling,
                getLayerTab = getModal.previousElementSibling,
                modalId = getModal.getAttribute('id'),
                getContent = document.querySelector('.configurator__content.active.current').previousElementSibling;

            getContent.classList.add('target');
            getLayerTab.classList.add('has-alert');
            back.setAttribute('href', '#'+modalId);

            const
                getTitle = document.querySelector('.configurator__layers .active.current').getAttribute('data-title'),
                getTargetStepTitle =  getLayerTab.getAttribute('data-title');

            // put target step title to popup
            if (getLayerTab.classList.contains('has-alert')){
                getModal.querySelector('.target-step').innerHTML = '„'+getTargetStepTitle+'“';
            }
            getModal.querySelector('.are-you-sure').innerHTML = '<strong>„'+getTitle+'“</strong>';

            // puts all titles from layers to reset to popup
            const firstChild = document.querySelector('.has-alert'),
                nextSiblings = nextAll(firstChild),
                previousSteps = nextSiblings
                    .reduce((steps, sibling) => {
                        if (!sibling.classList.contains('finished') && !sibling.classList.contains('active')) {
                            return steps;
                        }

                        const item = '<strong>„'+sibling.getAttribute('data-title')+'“</strong>'
                        steps.push(item);
                        return steps;
                    }, []);

            if (previousSteps.length > 1) {
                const lastStep = previousSteps.pop();
                let joinAll = previousSteps.join(', ');
                joinAll += ' und ' + lastStep;
                getModal.querySelector('.singular').style.display = 'none';
                getModal.querySelector('.plural').style.display = 'inline';
                getModal.querySelector('.are-you-sure').innerHTML = joinAll;
            } else {
                getModal.querySelector('.singular').style.display = 'inline';
                getModal.querySelector('.plural').style.display = 'none';
                getModal.querySelector('.are-you-sure').innerHTML = previousSteps[0];
            }

        } else {
            back.setAttribute('href', '#reset');
        }
    })
})

// Show alert on go back
function alertOnGoBack() {
    const finished = document.querySelectorAll('.configurator__layers a');

    finished.forEach(el => {
        el.addEventListener('click', () => {
            if (el.classList.contains('finished')) {
                const getModal = el.nextElementSibling,
                    modalId = getModal.getAttribute('id'),
                    getTitle = document.querySelector('.configurator__layers .active.current').getAttribute('data-title'),
                    getTargetStepTitle =  el.getAttribute('data-title');

                el.setAttribute('href', '#'+modalId);
                el.classList.add('has-alert');

                document.querySelector('.configurator__content[data-title="'+getTargetStepTitle+'"]').classList.add('target');

                // put target step title to popup
                if (el.classList.contains('has-alert')){
                    getModal.querySelector('.target-step').innerHTML = '„'+getTargetStepTitle+'“';
                }
                getModal.querySelector('.are-you-sure').innerHTML = '<strong>„'+getTitle+'“</strong>';

                // puts all titles from layers to reset to popup
                const firstChild = document.querySelector('.has-alert'),
                    nextSiblings = nextAll(firstChild),
                    previousSteps = nextSiblings
                        .reduce((steps, sibling) => {
                            if (!sibling.classList.contains('finished') && !sibling.classList.contains('active')) {
                                return steps;
                            }

                            const item = '<strong>„'+sibling.getAttribute('data-title')+'“</strong>'
                            steps.push(item);
                            return steps;
                        }, []);

                if (previousSteps.length > 1) {
                    const lastStep = previousSteps.pop();
                    let joinAll = previousSteps.join(', ');
                    joinAll += ' und ' + lastStep;
                    getModal.querySelector('.singular').style.display = 'none';
                    getModal.querySelector('.plural').style.display = 'inline';
                    getModal.querySelector('.are-you-sure').innerHTML = joinAll;
                } else {
                    getModal.querySelector('.singular').style.display = 'inline';
                    getModal.querySelector('.plural').style.display = 'none';
                    getModal.querySelector('.are-you-sure').innerHTML = previousSteps[0];
                }
            }
        })

        // remove has-alert, important
        const close = document.querySelectorAll('.alert .close');

        close.forEach(close => {
            close.addEventListener('click', () => {
                el.classList.remove('has-alert');
                el.setAttribute('href', '#');

                document.querySelectorAll('.target').forEach(target => {
                    target.classList.remove('target');
                })

                document.querySelectorAll('.to-back').forEach(toBack => {
                    toBack.setAttribute('href', '#');
                })
            })
        })
    })
}

alertOnGoBack();

// Go back and reset current step
function goBack() {
    const btnAlert = document.querySelectorAll('.btn-alert'),
        layerEls = document.querySelectorAll('.configurator__content');

    btnAlert.forEach(btn => {
        btn.addEventListener('click', () => {

            // Find target layer by .target class
            const targetLayerEl = Array.from(layerEls)
                .find((layer) => layer.classList.contains('target'))

            const targetLayerContentComponent = targetLayerEl.configuratorComponent
            const targetLayerTabComponent = targetLayerContentComponent.getLayerTab()

            // reset layers after the target tab
            targetLayerTabComponent.resetLayersAfterCurrent()

            //  Add .active class to .to-next_wrap of the target layer and activate it
            targetLayerContentComponent.getNextButtonEl().classList.add('active')
            targetLayerTabComponent.activate()

            // remove the alert from target layer tab
            targetLayerTabComponent.element.classList.remove('has-alert')
            targetLayerTabComponent.element.setAttribute('href', '#')

            // reload possible configuration options for the resetted layers
            main_ajax();
        })
    })
}

goBack();

function dropdown(){
    document.querySelectorAll('.dropdown').forEach(dropdown => {
        const options = dropdown.querySelectorAll('.dropdown__select-option'),
            getDropdwonChoise = dropdown.querySelector('.dropdown__switch'),
            label = dropdown.querySelector('.dropdown__filter-selected'),
            dropDownFilter = dropdown.querySelector('.dropdown__filter-selected');

        options.forEach(option => {
            option.addEventListener('click', () => {
                const getDataVal = option.getAttribute('data-val');

                label.textContent = option.textContent;
                getDropdwonChoise.setAttribute('value', getDataVal);
                dropdown.querySelector('.dropdown__select').style.transform = 'scaleY(0)';
            })

            dropDownFilter.addEventListener('click', (e) => {
                dropdown.querySelector('.dropdown__select').style.transform = 'scaleY(1)';
            })
        })
    })
}
dropdown();

// quantity button
export function setQuantity() {
    const plusBtn = document.querySelectorAll('.plus-button');
    const minusBtn = document.querySelectorAll('.minus-button');

    plusBtn.forEach(plus => {
        plus.addEventListener('click', function () {
            const getElement = plus.previousElementSibling,
                getValue = parseInt(getElement.value),
                getStep = parseInt(getElement.getAttribute('step')),
                jsAdaptor = document.querySelector('.js-adaptor');

            if (jsAdaptor && getElement.classList.contains('js-korb')) {
                jsAdaptor.value = parseInt(jsAdaptor.value) + 2;
                getElement.value++
            } else {
                if (getStep > 1) {
                    getElement.value = getValue + getStep;
                } else {
                    getElement.value++
                }
            }

            const getProductId = plus.dataset.id,
                getProduct = document.querySelector('.add-to-cart-addon.active[data-product="'+getProductId+'"]');

            updateQuantity();
            addToCartAddon(getProduct);
        })
    })

    minusBtn.forEach(minus => {
        minus.addEventListener('click', function () {
            const getElement = minus.nextElementSibling,
                getValue = parseInt(getElement.value),
                getStep = parseInt(getElement.getAttribute('step')),
                jsAdaptor = document.querySelector('.js-adaptor');

            if (jsAdaptor && getElement.classList.contains('js-korb')) {
                jsAdaptor.value = parseInt(jsAdaptor.value) - 2;
                getElement.value = getValue - 1;
            } else {
                getElement.value = (getValue <= 1) ? 1 : ((getValue > 1) ? (getValue - getStep) : getValue - 1);
            }

            if (getElement.value >= 1) {
                updateQuantity();
            } else {
                getElement.value == 1;
            }

            const getProductId = minus.dataset.id,
                getProduct = document.querySelector('.add-to-cart-addon.active[data-product="'+getProductId+'"]'),
                getProductQuantity = getProduct.dataset.quantity;

            updateQuantity();

            removeByQuantity(getProductId, getProductQuantity);
        })
    })
}

setQuantity();

// Update quantity
function updateQuantity() {
    let quantity_values = document.querySelectorAll('.quantity');

    quantity_values.forEach(quantity_value => {
        const getQuantity = quantity_value.value,
            getProductId = quantity_value.getAttribute('data-product');

        if (document.querySelector('.add-to-cart-button[data-product="'+getProductId+'"]')) {
            document.querySelectorAll('.add-to-cart-button[data-product="'+getProductId+'"]').forEach(cart => {cart.setAttribute('data-quantity', getQuantity);})
        }

        if (document.querySelector('.add-to-cart-addon[data-product="'+getProductId+'"]')) {
            document.querySelector('.add-to-cart-addon[data-product="'+getProductId+'"]').setAttribute('data-quantity', getQuantity);
        }
    })
}

function removeByQuantity(product_id, quantity) {
    var security = my_ajax_object.nonce;
    $.ajax({
        type: 'POST',
        url: ajaxUrl,
        data: {
            action: 'remove_quantity_from_cart',
            product_id: product_id,
            quantity: quantity,
            security: security,
        },
        beforeSend: function () {
            const
                currentAddonBox = document.querySelector('.addons_item[data-product="'+product_id+'"]'),
                currentAddonButton = currentAddonBox.querySelector('.add-to-cart-addon');

            currentAddonButton.style.pointerEvents = 'none';
            currentAddonButton.innerHTML = '';
            currentAddonButton.appendChild(document.getElementById('loader').content.cloneNode(true));
        },
        success: function( response ) {
            console.log(response)
            const
                currentAddonBox = document.querySelector('.addons_item[data-product="'+product_id+'"]'),
                currentAddonButton = currentAddonBox.querySelector('.add-to-cart-addon');
            currentAddonButton.classList.add('active');
            currentAddonButton.innerHTML = 'Hinzugefügt<span></span>';
        }
    });
}

document.querySelectorAll('.raster-box.radio-value-box input[type="radio"], [data-slug="material-unterbauschublade"] .radio-value-box input[type="radio"]').forEach(input => {
    input.addEventListener('click', () => {
        var values = $('input[type="radio"]:checked').map(function() {
            return $(this).val();
        }).get();

        $.ajax({
            type: 'POST',
            url: ajaxUrl,
            dataType: 'html',
            data: {
                action: 'change_main_image',
                values: values,
            },
            success: function (res) {
                document.querySelector('.configurator__img img').setAttribute('src', res);
            }
        })

    })
})

const radioButtons = document.querySelectorAll('input[type="radio"]');

radioButtons.forEach(function(radio) {
    radio.addEventListener('click', function() {
        this.dataset.status = 'true';
        radioButtons.forEach(function(otherRadio) {
            if (otherRadio !== radio) {
                otherRadio.dataset.status = 'false';
            }
        });

        var values = $('input[type="radio"]:checked').map(function() {
            return $(this).val();
        }).get();
    });

    radio.addEventListener('change', function() {
        this.dataset.status = 'true';
    });
});

var forms = document.querySelectorAll('form');
forms.forEach(form => {
    form.querySelector('button[type="submit"]').onclick = function (){
        function validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }

        Array.from(form.elements).forEach(element => {
            if(element.value === '' && element.hasAttribute('required')){
                element.style.borderColor = 'red';
                return false;

            } else if(element.value !== '' && element.hasAttribute('required')) {
                element.style.borderColor = '#EAECF0';

            }
        })

        const maiL = form.querySelector('#mail');

        if (validateEmail(maiL.value) === false) {
            maiL.style.borderColor = 'red';
            return false;
        } else {
            maiL.style.borderColor = '#EAECF0';
        }

        for(var i=0; i < form.elements.length; i++){
            if(form.elements[i].value === '' && form.elements[i].hasAttribute('required') ){
                return false;
            }
        }

        document.querySelector('.form-loader').style.display = 'flex';
        form.submit();
    };
})

